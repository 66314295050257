export const MailIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0.5H1.5C0.65625 0.5 0 1.17969 0 2V8C0 8.84375 0.65625 9.5 1.5 9.5H10.5C11.3203 9.5 12 8.84375 12 8V2C12 1.17969 11.3203 0.5 10.5 0.5ZM1.5 1.625H10.5C10.6875 1.625 10.875 1.8125 10.875 2V2.53906L6.96094 5.77344C6.42188 6.21875 5.55469 6.21875 5.01562 5.77344L1.125 2.53906V2C1.125 1.8125 1.28906 1.625 1.5 1.625ZM10.5 8.375H1.5C1.28906 8.375 1.125 8.21094 1.125 8V3.99219L4.3125 6.66406C4.78125 7.03906 5.36719 7.25 6 7.25C6.60938 7.25 7.19531 7.03906 7.66406 6.66406L10.875 3.99219V8C10.875 8.21094 10.6875 8.375 10.5 8.375Z"
        fill="#91A5BA"
      />
    </svg>
  );
};
